import "./SearchModal.css";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faNavicon,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

export default function SearchModal({ handleCloseSearchModal, handleSidebar }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [url, setUrl] = useState("/api/products");
  const inputElement = useRef();

  useEffect(() => {
    //useRef to place cursor in search input when SearchModal componenet opens
    inputElement.current.focus();
    setUrl(`/products/?q=${searchTerm}`);
  }, [searchTerm]);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(url);
    handleCloseSearchModal();
  };

  return (
    <div className="searchModal">
      <nav className="searchModalNav">
        <div className="menuIcon">
          <FontAwesomeIcon
            icon={faNavicon}
            className="navicon"
            onClick={handleSidebar}
          />
        </div>
        <div className="searchBar">
          <form onSubmit={handleSubmit} className="searchbox">
            <input
              type="text"
              className="searchBox"
              placeholder="Search everything at Granddad's Discount Groceries"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              ref={inputElement}
            />
          </form>
        </div>
        <Link to={url}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className="navicon" />
        </Link>
        <button onClick={handleCloseSearchModal}>Cancel</button>
      </nav>
    </div>
  );
}
