import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

// firebase imports
import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  const handleError = (error) => {
    let customErrorMessage = "";

    const errorCode = error.code;

    switch (errorCode) {
      case "auth/wrong-password":
        customErrorMessage =
          "Incorrect Password: To reset your password, click the reset password link below.";
        break;
      case "auth/user-not-found":
        customErrorMessage =
          "User not found. Don't have an account yet? Click the signup button.";
        break;
      case "auth/user-disabled":
        customErrorMessage =
          "Your account has been disabled. Please contact us at hello@granddadsgroceries.com";
        break;
      case "auth/invalid-email":
        customErrorMessage = "Invalid email";
        break;
      case "auth/too-many-requests":
        customErrorMessage =
          "Oops! Access to this account has been temporarily disabled due to to many failed login attempts: To reset your password, click the reset password link below.";
        break;
      default:
        customErrorMessage = error.message;
    }

    return customErrorMessage;
  };

  const login = (email, password) => {
    setError(null);
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
      })
      .catch((err) => {
        const customErrorMessage = handleError(err);

        setError(customErrorMessage);
      });
  };

  return { error, login };
};
