//styles
import { Link, useNavigate } from "react-router-dom";
import "./About.css";

export default function About({ showLearn }) {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/learn");
  };

  return (
    <>
      <h3>About Us</h3>
      <div className="about">
        <p className="about-p">Welcome to Granddad’s Discount Groceries! </p>
        <p className="about-p">
          We sell groceries by the case at discounted prices that you won’t
          usually find in your traditional retail store. Our products are
          wholesome groceries that our vendors primarily purchase directly from
          manufacturers due to closeouts, overruns, and distressed, past-date or
          close-to-date items.{" "}
        </p>
        <p className="about-p">
          We’re able to provide affordable, wholesome food to our customers,
          reducing their food budgets without sacrificing nutritious options.
        </p>
        {!showLearn && (
          <p className="about-p">
            If you’re a new customer, you can check out our{" "}
            <Link to="/learn">What You Should Know page</Link>.
          </p>
        )}
        {showLearn && (
          <button className="button about-button" onClick={handleNext}>
            Next
          </button>
        )}
      </div>
    </>
  );
}
