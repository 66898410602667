import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faCalendarCheck,
  faMagnifyingGlass,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

//styles
import "./ProductDates.css";

export default function ProductDates() {
  return (
    <div className="product-dates">
      <h3>Product Dates</h3>
      <div className="product-dates-grid">
        <div className="product-dates-flex">
          <FontAwesomeIcon
            icon={faCartShopping}
            className="product-dates-icon"
            size="2x"
          />
          <div className="product-dates-subflex">
            <p className="product-dates-header">Our Products</p>
            <p className="product-dates-paragraph">
              Here at Granddad’s Discount Groceries we sell wholesome groceries
              that our vendors primarily purchase from manufacturers due to
              closeouts, overruns, and distressed, past-date, or close to date
              items. This creates a double-win: less food waste for America and
              wholesome food at lower costs for our customers.
            </p>
          </div>
        </div>
        <div className="product-dates-flex">
          <FontAwesomeIcon
            icon={faCalendarCheck}
            className="product-dates-icon"
            size="2x"
          />
          <div className="product-dates-subflex">
            <p className="product-dates-header">
              Are past-date foods safe to eat?
            </p>
            <p className="product-dates-paragraph">
              Often, the answer is yes! According to the USDA, an estimated 30%
              of food in America is wasted by grocers and consumers. This is
              because people often discard food when it nears its expiration
              date. But “past-date” food is usually safe to eat if it has been
              stored properly and does not show signs of spoilage.
            </p>
          </div>
        </div>
        <div className="product-dates-flex">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="product-dates-icon"
            size="2x"
          />
          <div className="product-dates-subflex">
            <p className="product-dates-header">
              What do the date abbreviations stand for?
            </p>
            <p>
              <p>
                <b>BB</b> - Best By Date
              </p>
              <p>
                <b>UB</b> - Use By Date
              </p>
              <p>
                <b>EX</b> - Expiration Date
              </p>
              <p>
                <b>FB</b> - Freeze By Date
              </p>
              <p>
                <b>PD</b> - Production Date
              </p>
              <p>
                <b>SB</b> - Sell By Date
              </p>
            </p>
          </div>
        </div>
        <div className="product-dates-flex">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="product-dates-icon"
            size="2x"
          />
          <div className="product-dates-subflex">
            <p className="product-dates-header">More Questions?</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fsis.usda.gov/food-safety/safe-food-handling-and-preparation/food-safety-basics/food-product-dating"
            >
              <button className="button product-dates-button">
                Visit USDA.gov for More Info
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
