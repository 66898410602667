import { useState, useEffect, useCallback } from "react";

import "./Filter.css";

export default function Filter({
  category,
  categories,
  currentCategory,
  subCategory,
  handleNewFilter,
  temp,
  newest,
}) {
  const [subCategories, setSubCategories] = useState([]);

  const getSubCategories = useCallback(
    (c) => {
      categories.forEach((category) => {
        if (category["_id"] === c) {
          setSubCategories(category.subCategory);
        }
      });
    },
    [categories]
  );

  useEffect(() => {
    getSubCategories(currentCategory);
  }, [currentCategory, getSubCategories]);

  const temperatureArray = [
    ["Frozen", "F"],
    ["Dry Goods", "D"],
  ];

  return (
    <div className="product-filter">
      <nav>
        {!category && (
          <div className="sort">
            <p>Show:</p>
            <button
              onClick={() => handleNewFilter("newest", "false")}
              className={!newest ? "active" : ""}
            >
              All
            </button>
            <button
              key={"newest"}
              onClick={() => handleNewFilter("newest", "true")}
              className={newest === "true" ? "active" : ""}
            >
              Newest Items
            </button>
          </div>
        )}
        {category && (
          <div className="sort">
            <p>Filter by:</p>
            <button
              onClick={() => handleNewFilter("subCat", "All")}
              className={subCategory === "All" ? "active" : ""}
            >
              All
            </button>
            {subCategories.map((f) => (
              <button
                key={f}
                onClick={() => handleNewFilter("subCat", f)}
                className={subCategory === f ? "active" : ""}
              >
                {f}
              </button>
            ))}
          </div>
        )}
        <div className="sort">
          <p>Show only:</p>
          <button
            onClick={() => handleNewFilter("temp", "All")}
            className={temp === "All" ? "active" : ""}
          >
            All
          </button>
          {temperatureArray.map((t) => {
            const tempName = t[0];
            const tempKey = t[1];

            return (
              <button
                key={tempName}
                onClick={() => handleNewFilter("temp", tempKey)}
                className={temp === tempKey ? "active" : ""}
              >
                {tempName}
              </button>
            );
          })}
        </div>
      </nav>
    </div>
  );
}
