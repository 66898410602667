import logo from "../images/GDG-Parsons.png";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// firebase imports
import { auth } from "../../firebase/config";
import { sendPasswordResetEmail } from "firebase/auth";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [continueButton, setContinueButton] = useState(false);

  const navigate = useNavigate();

  const handleError = (error) => {
    let customErrorMessage = "";

    const errorCode = error.code;

    switch (errorCode) {
      case "auth/wrong-password":
        customErrorMessage =
          "Incorrect Password: To reset your password, click the reset password link below.";
        break;
      case "auth/user-not-found":
        customErrorMessage =
          "User not found. Don't have an account yet? Go to the signup page.";
        break;
      case "auth/user-disabled":
        customErrorMessage =
          "Your account has been disabled. Please contact us at hello@granddadsgroceries.com";
        break;
      case "auth/invalid-email":
        customErrorMessage = "Invalid email";
        break;
      case "auth/too-many-requests":
        customErrorMessage =
          "Oops! Access to this account has been temporarily disabled due to to many failed login attempts: To reset your password, click the reset password link below.";
        break;
      default:
        customErrorMessage = error.message;
    }

    return customErrorMessage;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setContinueButton(true);
      })
      .catch((error) => {
        const customErrorMessage = handleError(error);
        setError(customErrorMessage);
        setContinueButton(false);
      });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="login grid">
      <img src={logo} alt="" className="logo" />
      <h3>Forgot your password?</h3>

      {!continueButton && (
        <form onSubmit={handleSubmit}>
          <p>
            No problem! Enter your email below and we'll send a code to reset
            it.
          </p>
          <input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Email"
            autoComplete="on"
          />
          <button className="login-button">Submit</button>
          {error && <p className="signup-error">{error}</p>}
          <p className="signup-note">
            Remember your password? {<Link to="/login">Login</Link>}{" "}
          </p>
        </form>
      )}
      {continueButton && (
        <form onSubmit={handleContinue}>
          <p>
            Password reset email sent! If it doesn't arrive, be sure to check
            your spam folder.
          </p>
          <button className="login-button">Continue</button>
          <p className="signup-note">
            Remember your password? {<Link to="/login">Login</Link>}{" "}
          </p>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
