import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6ojLZreJpQVP-MHpVct6fBe0v2KTqX3g",
  authDomain: "granddads-groceries.firebaseapp.com",
  projectId: "granddads-groceries",
  storageBucket: "granddads-groceries.appspot.com",
  messagingSenderId: "18636941186",
  appId: "1:18636941186:web:e3f9e21166fd605288519b",
};

// init firebase
const firebaseApp = initializeApp(firebaseConfig);

// init firebase auth
const auth = getAuth(firebaseApp);

export { auth };
