import { useFetch } from "../../hooks/useFetch";
import { useNavigate } from "react-router";
import { useAuthContext } from "../../hooks/useAuthContext";
import Loading from "../Loading";

import "./UserOrders.css";

const UserOrders = () => {
  const { user } = useAuthContext();
  const { data, isPending, error } = useFetch(`/api/orders/user/${user.uid}`);
  const navigate = useNavigate();

  const handleDetails = (order) => {
    navigate(`/orders/${order._id}`);
  };

  return (
    <div>
      <h1>Previous Orders</h1>
      {isPending && <Loading />}
      {data &&
        data.map((order) => {
          return (
            <div key={order._id} className="orderPreview">
              <div>
                <p>
                  {new Date(order.orderTimestamp).toDateString()} | Total $
                  {Number.parseFloat(order.orderTotal / 100).toFixed(2)} |{" "}
                  {order.fulfillment ? order.fulfillment.type : ""}
                </p>
                <p>{order.products.length} Items</p>
              </div>
              <button className="button" onClick={() => handleDetails(order)}>
                See Details
              </button>
            </div>
          );
        })}
      {data && data.length === 0 && <p>You have no previous orders</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default UserOrders;
