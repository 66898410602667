const handleTotal = (cart) => {
  let newCartTotal = 0;
  cart.forEach((x) => {
    let itemPrice = x.casePrice;
    let itemQuantity = x.quantity;
    newCartTotal = newCartTotal + itemPrice * itemQuantity;
  });

  return newCartTotal;
};

const handleItemCount = (cart) => {
  let newItemCount = 0;
  cart.forEach((x) => {
    let itemQuantity = x.quantity;
    newItemCount = newItemCount + itemQuantity;
  });

  return newItemCount;
};

const cartReducer = (state, action) => {
  const prevCart = state.cart ? state.cart : [];
  const item = action.payload ? action.payload : null;
  let cart = [];
  let exist = item
    ? prevCart.find((x) => x.productId === item.productId)
    : false;

  switch (action.type) {
    case "EMPTY_CART":
      return { ...state, cart: [], cartTotal: 0, itemCount: 0 };
    case "SYNC_CART":
      return { ...action.payload };
    case "FULFILLMENT_INFO":
      return { ...state, fulfillment: action.payload };
    case "ADD_ITEM":
      if (exist) {
        cart = prevCart.map((x) =>
          x.productId === item.productId
            ? { ...exist, quantity: exist.quantity + 1 }
            : x
        );
      } else {
        cart = [...prevCart, { ...item, quantity: 1 }];
      }
      break;
    case "REMOVE_ITEM":
      if (exist.quantity === 1) {
        cart = prevCart.filter((x) => x.productId !== item.productId);
      } else {
        cart = prevCart.map((x) =>
          x.productId === item.productId
            ? { ...exist, quantity: exist.quantity - 1 }
            : x
        );
      }
      break;
    default:
      throw new Error();
  }

  let newCartTotal = handleTotal(cart);
  let itemCount = handleItemCount(cart);

  return {
    ...state,
    cart: cart,
    cartTotal: newCartTotal,
    itemCount: itemCount,
  };
};

export default cartReducer;
