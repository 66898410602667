//images
import canned from "./components/images/canned.jfif";
import dairy from "./components/images/dairy.jfif";
import meat from "./components/images/meat.jfif";
import processed from "./components/images/processed.jfif";
import bakery from "./components/images/bakery.jfif";
import snacks from "./components/images/snacks.jfif";
import condiments from "./components/images/condiments.jfif";
import beverages from "./components/images/beverages.jfif";
import vegetables from "./components/images/vegetables.jfif";
import cart from "./components/images/shopping-cart.png";
import spices from "./components/images/spices.jfif";
import newArrival from "./components/images/new arrival.png";

export default function homeCategories() {
  const categories = [
    {
      name: "Newest Items",
      image: newArrival,
      url: "/products/?newest=true",
    },
    {
      name: "Dairy",
      image: dairy,
      url: "/products/?category=DAIRY",
    },
    {
      name: "Meat & Seafood",
      image: meat,
      url: `/products/?category=${encodeURIComponent("MEAT & SEAFOOD")}`,
    },
    {
      name: "Prepared Foods",
      image: processed,
      url: "/products/?category=ENTREE",
    },
    {
      name: "Bread, Rolls & Bakery",
      image: bakery,
      url: "/products/?category=BAKERY",
    },
    {
      name: "Snacks, Chips, Candy",
      image: snacks,
      url: "/products/?category=SNACKS",
    },
    {
      name: "Pantry",
      image: spices,
      url: "/products/?category=PANTRY",
    },
    {
      name: "Condiments",
      image: condiments,
      url: "/products/?category=CONDIMENTS",
    },
    {
      name: "Beverages",
      image: beverages,
      url: "/products/?category=BEVERAGE",
    },
    {
      name: "Vegetables",
      image: vegetables,
      url: "/products/?category=VEGETABLES",
    },
    {
      name: "All Items",
      image: cart,
      url: "/products/",
    },
    {
      name: "Canned Goods",
      image: canned,
      url: "/products/?category=CANNED GOODS",
    },
  ];
  return categories;
}
