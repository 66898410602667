//style
import "./CheckoutFooter.css";

//custom hooks
import { useLocation } from "../../hooks/useLocation";

export default function CheckoutFooter({
  cart,
  cartTotal,
  clickAction,
  text,
  disabled,
}) {
  const { location } = useLocation();

  const ordersOpen = location ? location.ordersOpen : null;
  const nextOrderWindowShort = location ? location.nextOrderWindowShort : null;

  return (
    <>
      <div className="whiteSpace" />
      <div className="checkOut">
        {cart.length > 0 && (
          <p className="totalText">
            Cart Total: ${Number.parseFloat(cartTotal / 100).toFixed(2)}
          </p>
        )}
        {!ordersOpen && (
          <div className="window-notification">
            <span>Our next order window is {nextOrderWindowShort}. </span>
            <span>We'll remind you when it's time to place your order!</span>
          </div>
        )}
        {cart.length > 0 && (
          <button
            className={
              ordersOpen && !disabled
                ? "button checkoutButton"
                : "button disabledButton"
            }
            onClick={clickAction}
          >
            {text}
          </button>
        )}
      </div>
    </>
  );
}
