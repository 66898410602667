import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useCart } from "../../hooks/useCart";
import { useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

//images
import groceryBag from "../../components/images/GroceryBag.PNG";

//styles
import "./Confirmation.css";

export default function Confirmation() {
  const { updateCart } = useCart();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const emptyCart = () => {
    updateCart("EMPTY_CART");
  };

  useEffect(() => {
    emptyCart();
  }, []);

  return (
    <div className="confirmation">
      <FontAwesomeIcon
        className="confirmation-checkmark"
        icon={faCheckCircle}
      />
      <h3>Thanks for your order!</h3>
      <p className="confirmation-text">
        A receipt will be sent to <b>{user.email}</b>.
      </p>
      <p>
        To review your order, visit the{" "}
        <b>
          <span
            className="confirmation-link"
            onClick={() => {
              navigate("/orders");
            }}
          >
            Previous Orders
          </span>
        </b>{" "}
        page.
      </p>
      <p className="confirmation-text">
        Questions about your order?{" "}
        <span
          className="confirmation-link"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </span>
      </p>
      <img src={groceryBag} alt="Grocery Bag" className="confirmation-image" />
    </div>
  );
}
