import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

// firebase imports
import { auth } from "../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const useSignup = () => {
  const handleError = (error) => {
    let customErrorMessage = "";

    const errorCode = error.code;

    switch (errorCode) {
      case "auth/email-already-in-use":
        customErrorMessage = "There is already an account with this email.";
        break;
      case "auth/invalid-email":
        customErrorMessage = "Invalid Email";
        break;
      case "auth/weak-password":
        customErrorMessage = "This password is too weak.";
        break;
      default:
        customErrorMessage = error.message;
    }

    return customErrorMessage;
  };

  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();

  const createUserDoc = async (userId, userObject) => {
    const { password, confirmPassword, ...userDoc } = userObject;

    userDoc._id = userId;

    const newUserUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:4000/api/user/create"
        : "/api/user/create";

    // This will send a post request to update the data in the database.
    await fetch(newUserUrl, {
      method: "POST",
      body: JSON.stringify(userDoc),
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((e) => {
      console.log(e);
    });
  };

  const signup = (userObject) => {
    setError(null);
    if (userObject.password === userObject.confirmPassword) {
      createUserWithEmailAndPassword(
        auth,
        userObject.email,
        userObject.password
      )
        .then((res) => {
          createUserDoc(res.user.uid, userObject);
          dispatch({ type: "LOGIN", payload: res.user });
        })
        .catch((err) => {
          const customErrorMessage = handleError(err);

          setError(customErrorMessage);
        });
    } else {
      setError("Passwords do not match");
    }
  };

  return { error, signup };
};
