import "./Products.css";
import ProductCard from "./Card";
import { useSearchParams } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Filter from "./Navigation/Filter";
import Loading from "./Loading";

//custom hooks
import { useAuthContext } from "../hooks/useAuthContext";
import { useCart } from "../hooks/useCart";
import getAuthHeaders from "../utils/getAuthHeaders";

const Products = ({ categories }) => {
  //to record client cart
  const { cart, cartTotal } = useCart();
  const { user } = useAuthContext();

  //to handle catagories and pages
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category") || null;
  const query = searchParams.get("q") || null;
  const pageNum = searchParams.get("page") || 1;
  const subCategory = searchParams.get("subCategory") || null;
  const displayAll = searchParams.get("display") || null;
  const popular = searchParams.get("popular") || null;
  const temp = searchParams.get("temp") || null;
  const newest = searchParams.get("newest") || null;

  const [url, setUrl] = useState("/api/products");

  const { data, isPending, error } = useFetch(url);

  let items = null;
  let resultsCount = null;

  if (data) {
    if (data.docs) {
      items = data.docs;
    }

    if (data.meta) {
      if (data.meta.length > 0) {
        resultsCount = data.meta[0].total;
      }
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const generateUrl = () => {
      let newUrl = "/api/products?";

      if (popular === "TRUE") {
        newUrl = "/api/products/popular?";
      }

      let parameters = "";
      const paramArray = [];

      if (query) {
        paramArray.push(`&q=${encodeURIComponent(query)}`);
      }
      if (category) {
        paramArray.push(`&category=${encodeURIComponent(category)}`);
      }
      if (pageNum) {
        paramArray.push(`&page=${pageNum}`);
      }
      if (subCategory && subCategory !== "All") {
        paramArray.push(`&subCategory=${encodeURIComponent(subCategory)}`);
      }
      if (temp && temp !== "All") {
        paramArray.push(`&temp=${encodeURIComponent(temp)}`);
      }

      if (newest && newest !== "false") {
        paramArray.push(`&newest=${encodeURIComponent(newest)}`);
      }
      if (displayAll) {
        paramArray.push(`&display=ALL`);
      }

      paramArray.forEach((param) => {
        parameters += param;
      });
      setUrl(newUrl + parameters);
    };

    generateUrl();
  }, [
    query,
    pageNum,
    category,
    subCategory,
    displayAll,
    popular,
    temp,
    newest,
  ]);

  //to record client cart
  useEffect(() => {
    const recordCart = async () => {
      try {
        const authHeader = await getAuthHeaders(user);

        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...authHeader,
          },
        };

        // Record cart at the beginning of the checkout process
        const fetchUrl =
          process.env.NODE_ENV === "development"
            ? `${process.env.REACT_APP_NETWORK}:4000/api/user/record/${user.uid}`
            : `/api/user/record/${user.uid}`;

        await fetch(fetchUrl, options); // Use await here
      } catch (error) {
        console.error(error);
      }
    };

    recordCart(); // Call the async function
  }, [user, cart]);

  const handleNextPage = (upOrDown) => {
    const parameters = {};

    if (popular) {
      parameters.popular = popular;
    }

    if (query) {
      parameters.q = query;
    }
    if (category) {
      parameters.category = category;
    }
    if (subCategory) {
      parameters.subCategory = subCategory;
    }
    if (temp) {
      parameters.temp = temp;
    }
    if (newest) {
      parameters.newest = newest;
    }
    if (pageNum) {
      parameters.page = Number(pageNum) + upOrDown;
    } else {
      parameters.page = 2;
    }
    setSearchParams(parameters);
  };

  const handleNewFilter = (type, filter) => {
    const parameters = {};

    if (category) {
      parameters.category = category;
    }
    if (subCategory) {
      parameters.subCategory = subCategory;
    }
    if (temp) {
      parameters.temp = temp;
    }

    if (newest && filter !== "false") {
      parameters.newest = newest;
    }

    if (filter) {
      if (type === "subCat") {
        parameters.subCategory = filter;
      }
      if (type === "temp") {
        parameters.temp = filter;
      }

      if (type === "newest" && filter !== "false") {
        parameters.newest = filter;
      }
    }

    setSearchParams(parameters);
  };

  const handleFinalPageNumber = () => {
    if (Number(pageNum) * 36 - resultsCount < 36) {
      return Number(pageNum) * 36 < resultsCount
        ? Number(pageNum) * 36
        : resultsCount;
    } else {
      return Number(pageNum) * 36;
    }
  };

  return (
    <div className="home">
      {category && <h1>{category.toUpperCase()}</h1>}
      {popular && <h1>What are GDG Customers buying?</h1>}
      {items && !query && !popular && (
        <Filter
          categories={categories}
          currentCategory={category}
          subCategory={subCategory}
          handleNewFilter={handleNewFilter}
          temp={temp}
          newest={newest}
          category={category}
        />
      )}
      {items && query && resultsCount && (
        <p className="results">{`${resultsCount} results for "${query}"`}</p>
      )}
      {error && <div>{error}</div>}
      {!isPending && items < 1 && !query && (
        <p className="results">{`Oops! No products found. Try another category.`}</p>
      )}
      {!isPending && items < 1 && query && (
        <p className="results">{`There were no search results for ${query}`}</p>
      )}
      {isPending && <Loading />}
      <div className="product-grid">
        {items &&
          items.map((item) => {
            return <ProductCard key={item._id} item={item}></ProductCard>;
          })}
      </div>
      <div className="upper-pageNavigation">
        {resultsCount &&
          `Showing items ${
            Number(pageNum) > 1 ? (Number(pageNum) - 1) * 36 + 1 : 1
          }-${handleFinalPageNumber()} of ${resultsCount}`}
        <div className="pageNavigation">
          {Number(pageNum) > 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="chevron"
              onClick={() => handleNextPage(-1)}
            />
          )}
          {Number(pageNum) === 1 && (
            <FontAwesomeIcon
              icon={faChevronCircleLeft}
              className="disabled-chevron"
            />
          )}
          <p>{pageNum}</p>
          {Number(pageNum) * 36 < resultsCount && (
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="chevron"
              onClick={() => handleNextPage(1)}
            />
          )}
          {!(Number(pageNum) * 36 < resultsCount) && (
            <FontAwesomeIcon
              icon={faChevronCircleRight}
              className="disabled-chevron"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
