import "./Auth.css";

const Auth = ({ form }) => {
  return (
    <div className="background-image">
      <div className="login-flex">
        <div className="login-modal">{form}</div>
      </div>
    </div>
  );
};

export default Auth;
