import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1, fa2, fa3, fa4 } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//styles
import "./Learn.css";

export default function Learn({ showLearn }) {
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/tips");
  };
  return (
    <div className="learn">
      <h3>What You Should Know</h3>
      <div className="learn-grid">
        <div className="learn-child">
          <FontAwesomeIcon icon={fa1} className="learn-icon" size="3x" />
          <p className="learn-header">Finding the Best Deals</p>
          <p className="learn-paragraph learn-justify">
            {" "}
            We display our full inventory in order to give you the widest
            selection possible. Not every price will be a steal, so the key to
            success is watching for the products that are the best bargains.{" "}
          </p>
        </div>
        <div className="learn-child">
          <FontAwesomeIcon icon={fa2} className="learn-icon" size="3x" />
          <p className="learn-header">Our Specialty</p>
          <p className="learn-paragraph learn-justify">
            We sell groceries by the case at discounted prices. Our groceries
            come from vendors specializing in manufacturer closeouts, overruns,
            and distressed, past-date or close-to-date items. This allows us to
            provide affordable, wholesome food to our customers, reducing their
            food budgets without sacrificing a nutritious diet.{" "}
          </p>
        </div>
        <div className="learn-child">
          <FontAwesomeIcon icon={fa3} className="learn-icon" size="3x" />
          <p className="learn-header">Order Online, Local Pickup</p>
          <p className="learn-paragraph">
            We're a local business. You place your orders online and pick them
            up in Parsons, Altamont, Oswego, or Caney.
          </p>
        </div>
        <div className="learn-child">
          <FontAwesomeIcon icon={fa4} className="learn-icon" size="3x" />
          <p className="learn-header">Monthly Pickup Date</p>
          <p className="learn-paragraph">
            We place an order every other month, and our customers have a one
            week order window to place their order. If you miss an order window,
            no worries! There's always next month!
          </p>
        </div>
      </div>
      <button className="button learn-button" onClick={handleNext}>
        {!showLearn ? "Understanding our Product Cards" : "Next"}
      </button>
    </div>
  );
}
