import ProductCard from "../Card";
import { useNavigate } from "react-router";
import { useEffect } from "react";

//components
import CheckoutFooter from "./CheckoutFooter";

//custom hooks
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCart } from "../../hooks/useCart";
import getAuthHeaders from "../../utils/getAuthHeaders";

const Cart = () => {
  const navigate = useNavigate();
  const { cart, cartTotal, updateCart } = useCart();
  const { user } = useAuthContext();

  const HandleCheckout = () => {
    navigate("/cart/fulfillment");
  };

  useEffect(() => {
    cart.forEach((item) => {
      if (item.quantityAvailable < 1) {
        alert(`Sorry! The product ${item.description} is now out of stock!`);
      }
    });
  }, []); // <-- empty dependency array means the function runs only once

  useEffect(() => {
    const syncCart = async () => {
      try {
        if (cart.length > 0) {
          const productIdArray = cart.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
          }));
          const authHeader = await getAuthHeaders(user);
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...authHeader,
            },
            body: JSON.stringify({ productObjects: productIdArray }),
          };

          // Update cart as soon as the page loads
          const fetchUrl =
            process.env.NODE_ENV === "development"
              ? `${process.env.REACT_APP_NETWORK}:4000/api/user/cart/${user.uid}`
              : `/api/user/cart/${user.uid}`;
          fetch(fetchUrl, options).then(async (r) => {
            const cartObject = await r.json();
            updateCart("SYNC_CART", { ...cartObject });
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    syncCart();
  }, [user]);

  return (
    <div>
      {<h1>Shopping Cart</h1>}
      {cart.length === 0 && <h3>Cart is Empty</h3>}
      <div className="product-grid shopping-cart">
        {cart.length > 0 &&
          cart.map((item) => {
            return <ProductCard key={item._id} item={item}></ProductCard>;
          })}
      </div>
      <CheckoutFooter
        cart={cart}
        cartTotal={cartTotal}
        clickAction={HandleCheckout}
        text="Checkout"
      />
    </div>
  );
};

export default Cart;
