import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";

//images
import demo from "../images/demo.png";

//styles
import "./Tips.css";

export default function Tips({ showLearn, setShowLearn }) {
  const navigate = useNavigate();

  const handleNext = () => {
    setShowLearn(false);
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="tips grid">
      <h3 className="tips-parent-header">Understanding Our Product Cards</h3>
      <div className="tips-parent-2">
        <img src={demo} alt="" className="tips-image" />
      </div>
      <div className="tips-parent-3">
        <div className="tips-child">
          <p className="tips-header">Storage Type</p>
          <p className="tips-paragraph">
            Frozen, Refrigerated, or Non-Perishable
          </p>
        </div>
        <div className="tips-child">
          <p className="tips-header">New</p>
          <p className="tips-paragraph">
            Items that have been added in the last three days
          </p>
        </div>
        <div className="tips-child">
          <p className="tips-header">Price per Case</p>
          <p className="tips-paragraph">
            Price per case on top, price per item below
          </p>
        </div>
        <div className="tips-child">
          <p className="tips-header">Cases Available</p>
          <p className="tips-paragraph">
            Number of cases available from our suppliers
          </p>
        </div>
        <div className="tips-child">
          <p className="tips-header">Product Date</p>
          <div className="tips-paragraph">
            <p>
              <b>BB</b> - Best By Date
            </p>
            <p>
              <b>UB</b> - Use By Date
            </p>
            <p>
              <b>EX</b> - Expiration Date
            </p>
            <p>
              <b>FB</b> - Freeze By Date
            </p>
            <p>
              <b>PD</b> - Production Date
            </p>
            <p>
              <b>SB</b> - Sell By Date
            </p>
          </div>
        </div>
        <div className="tips-child">
          <FontAwesomeIcon
            icon={faCalendarCheck}
            className="product-dates-icon"
            size="2x"
          />
          <div className="product-dates-subflex">
            <p className="product-dates-header">
              Are past-date foods safe to eat?
            </p>
            <p className="product-dates-paragraph">
              Often, the answer is yes! According to the USDA, an estimated 30%
              of food in America is wasted by grocers and consumers. This is
              because people often discard food when it nears its expiration
              date. But “past-date” food is usually safe to eat if it has been
              stored properly and does not show signs of spoilage.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fsis.usda.gov/food-safety/safe-food-handling-and-preparation/food-safety-basics/food-product-dating"
            >
              <button className="button product-dates-button">
                Visit USDA.gov for More Info
              </button>
            </a>
          </div>
        </div>
      </div>
      {showLearn && (
        <button className="button tips-button" onClick={() => handleNext()}>
          Start Shopping!
        </button>
      )}
    </div>
  );
}
