import { createContext, useEffect, useReducer } from "react";

import cartReducer from "./CartReducer";

export const CartContext = createContext();

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(
    cartReducer,
    { cart: [], cartTotal: 0, itemCount: 0 },
    () => {
      const localData = localStorage.getItem("cart");
      return localData
        ? JSON.parse(localData)
        : { cart: [], cartTotal: 0, itemCount: 0 };
    }
  );

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state));
  }, [state]);

  const updateCart = (action, item) => {
    dispatch({ type: action, payload: item });
  };

  return (
    <CartContext.Provider value={{ ...state, updateCart }}>
      {children}
    </CartContext.Provider>
  );
}
