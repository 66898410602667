import { useState, useEffect } from "react";
import {
  useStripe,
  PaymentElement,
  useElements,
} from "@stripe/react-stripe-js";
import { useAuthContext } from "../../hooks/useAuthContext";

export default function Payform({ formattedCartTotal, handleErrorCheck }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuthContext();

  const [message, setMessage] = useState(null);
  const [isProcessing, setisProcessing] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    //gets the client's unique checkout code from the parametesr
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Remove any previous error messages
    setMessage(null);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    handleErrorCheck();

    setisProcessing(true);

    let return_url = "https://shop.granddadsgroceries.com/cart/confirmation";

    console.log(`REACT_APP_STRIPE_ENV: ${process.env.REACT_APP_STRIPE_ENV}`);
    console.log(`REACT_APP_NODE_ENV: ${process.env.REACT_APP_NODE_ENV}`);
    console.log(`NODE_ENV: ${process.env.NODE_ENV}`);

    /*
        if (process.env.REACT_APP_STRIPE_ENV !== "production") {
          return_url =
            process.env.NODE_ENV === "production"
              ? "https://gdg-staging.herokuapp.com/cart/confirmation"
              : `${process.env.REACT_APP_NETWORK}:3000/cart/confirmation`;
        }
        */

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setisProcessing(false);
  };

  return (
    <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        className="button"
      >
        <span id="button-text">
          {isProcessing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            `Place order for $${formattedCartTotal}`
          )}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
