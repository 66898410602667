import { useContext } from "react";
import { LocationContext } from "../context/LocationContext";

export const useLocation = () => {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error();
  }
  return context;
};
