import { useFetch } from "../../hooks/useFetch";
import { useParams } from "react-router-dom";

import "./DigitalInvoice.css";

const DigitalInvoice = () => {
  let { id } = useParams();
  const { data: order, error } = useFetch(`/api/orders/${id}`);

  let cartTotal = 0;
  let numOfCases = 0;

  const handleRow = (product) => {
    let { productId, description, quantity, casePrice, unitSize } = product;

    if (quantity > 0) {
      let rowTotal = casePrice * quantity;
      let price = casePrice / 100;
      let amount = rowTotal / 100;

      quantity = quantity > 0 ? quantity : "Out of Stock";
      casePrice = price.toFixed(2);
      amount = amount.toFixed(2);

      cartTotal += rowTotal;
      numOfCases += quantity;

      return (
        <div className="invoice-grid-item" key={productId}>
          <div>
            <p>
              {description}, {unitSize}
            </p>
            <p>
              <b>${Number.parseFloat(amount).toFixed(2)}</b>
            </p>
          </div>
          <div>
            <p>Qty {quantity}</p>
            <p>${Number.parseFloat(casePrice).toFixed(2)} each</p>
          </div>
          <div className="totalRadius">
            <p>Product ID: #{productId}</p>
          </div>
        </div>
      );
    }
  };

  const handleCartTotal = () => {
    const cartSubtotal =
      order.fulfillment.type === "Delivery"
        ? cartTotal + order.fulfillment.deliveryFee
        : cartTotal;
    const totalWithTax = order
      ? (order.taxRate.food + 10000) / Math.pow(10, 6)
      : 0;
    return Number.parseFloat(cartSubtotal * totalWithTax + 0.0001).toFixed(2); //0.0001 helps prevent rounding errors
  };

  const handleTaxTotal = () => {
    const cartSubtotal =
      order.fulfillment.type === "Delivery"
        ? cartTotal + order.fulfillment.deliveryFee
        : cartTotal;
    const foodTax = order ? order.taxRate.food / Math.pow(10, 6) : 0;
    return Number.parseFloat(cartSubtotal * foodTax + 0.0001).toFixed(2); //0.0001 helps prevent rounding errors
  };

  return (
    <div className="order-details ">
      {order && (
        <h1>
          {new Date(order.orderTimestamp).toDateString()} | $
          {Number.parseFloat(order.orderTotal / 100).toFixed(2)}
        </h1>
      )}
      {order && (
        <div className="invoice-grid">
          {order.products.map((product) => {
            return handleRow(product);
          })}
          <div className="invoice-grid-item" key="order-total">
            <p className="cartTotal">Cart Total</p>
            <div>
              <p>Subtotal | {numOfCases} Items</p>
              <p>${Number.parseFloat(cartTotal / 100).toFixed(2)}</p>
            </div>
            {order.fulfillment.type === "Delivery" && (
              <div>
                <p>Delivery</p>
                <p>
                  $
                  {Number.parseFloat(
                    order.fulfillment.deliveryFee / 100
                  ).toFixed(2)}
                </p>
              </div>
            )}
            <div>
              <p>Taxes</p>
              <p>${handleTaxTotal()}</p>
            </div>
            <div className="totalRadius">
              <p>
                <b>Total</b>
              </p>
              <p>
                <b>${handleCartTotal()}</b>
              </p>
            </div>
          </div>
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default DigitalInvoice;
