import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//components
import Pickup from "./Pickup";
import CheckoutFooter from "./CheckoutFooter";

//styles
import "./Fulfillment.css";
import "./Pickup.css";

//custom hooks
import { useLocation } from "../../hooks/useLocation";
import { useCart } from "../../hooks/useCart";
import { useAuthContext } from "../../hooks/useAuthContext";
import getAuthHeaders from "../../utils/getAuthHeaders";

export default function PickupOnly() {
  const [fulfillmentObject, setFulfillmentObject] = useState({});
  const [disableContinue, setDisableContinue] = useState(true);

  const navigate = useNavigate();
  const { cart, cartTotal, updateCart } = useCart();
  const { location, setLocation } = useLocation();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      const getLocation = async () => {
        try {
          const authHeader = await getAuthHeaders(user);

          let locationUrl = `/api/regions/${user.uid}`;

          if (process.env.NODE_ENV === "development") {
            locationUrl = `${process.env.REACT_APP_NETWORK}:4000${locationUrl}`;
          }

          fetch(locationUrl, { headers: authHeader }).then(async (res) => {
            const json = await res.json();
            setLocation(json);
          });
        } catch (err) {
          console.log(err.message);
        }
      };

      getLocation();
    }
  }, []);

  const HandleCheckout = () => {
    updateCart("FULFILLMENT_INFO", fulfillmentObject);
    navigate("/cart/checkout");
  };

  return (
    <div>
      <div className="fulfillment">
        <Pickup
          setDisableContinue={setDisableContinue}
          setFulfillmentObject={setFulfillmentObject}
          location={location}
        />
      </div>
      <CheckoutFooter
        cart={cart}
        cartTotal={cartTotal}
        clickAction={HandleCheckout}
        text={"Continue"}
        disabled={disableContinue}
      />
    </div>
  );
}
