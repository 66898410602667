import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarWeek,
  faBoxOpen,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

//styles
import "./OrderInfo.css";
import { useEffect } from "react";

export default function OrderInfo({ openNotification }) {
  useEffect(() => {
    openNotification();
  }, [openNotification]);

  return (
    <>
      <h3>How Our Ordering Schedule Works</h3>
      <div className="order-info-grid">
        <div className="order-info-child">
          <FontAwesomeIcon
            icon={faClock}
            className="order-info-icon"
            size="3x"
          />
          <p className="order-info-header">Order Deadline</p>
          <p className="order-info-paragraph">
            This is the date that orders are due by in order to be included on
            the next pickup date.
          </p>
        </div>
        <div className="order-info-child">
          <FontAwesomeIcon
            icon={faCalendarWeek}
            className="order-info-icon"
            size="3x"
          />
          <p className="order-info-header">Estimated pickup/delivery date</p>
          <p className="order-info-paragraph">
            This is the estimated day that your items will be ready for pickup.
            This can vary due to freight schedules, but we'll let you know if
            that changes. (We receive deliveries once-a-month)
          </p>
        </div>
        <div className="order-info-child">
          <FontAwesomeIcon
            icon={faBoxOpen}
            className="order-info-icon"
            size="3x"
          />
          <p className="order-info-header">Recommended Order Window</p>
          <p className="order-info-paragraph">
            This is week we recommend to place your order. Items are less likely
            to go out of stock before your order is fulfilled.
          </p>
        </div>
      </div>
    </>
  );
}
