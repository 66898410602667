const getAuthHeaders = async (user) => {
  try {
    const idToken = await user.getIdToken();
    return {
      Authorization: `Bearer ${idToken}`,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default getAuthHeaders;
