import { useState, useEffect } from "react";
import getAuthHeaders from "../utils/getAuthHeaders";
import { useAuthContext } from "./useAuthContext";

export const useFetch = (url) => {
  const { user } = useAuthContext();

  if (process.env.NODE_ENV === "development") {
    url = `${process.env.REACT_APP_NETWORK}:4000${url}`;
  }

  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsPending(true);
    const controller = new AbortController();
    const fetchData = async () => {
      const authHeader = await getAuthHeaders(user);
      let options = {
        headers: authHeader,
        signal: controller.signal,
      };

      try {
        const res = await fetch(url, options);

        if (!res.ok) {
          throw new Error(res.statusText);
        }

        const json = await res.json();

        setIsPending(false);
        setData(json);
        setError(null);
      } catch (err) {
        if (err.name === "AbortError") {
          //The fetch was aborted
        } else {
          setIsPending(false);
          setError("Could not fetch the data");
          console.log(err.message);
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [url, user]);

  return { data, isPending, error };
};
