import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

//images
import groceryBag from "../../components/images/GroceryBag.PNG";

//styles
import "./Contact.css";

export default function Contact() {
  return (
    <div style={{ textAlign: "center" }}>
      <h3>Contact Us</h3>
      <div className="contact-flex">
        <FontAwesomeIcon className="contact-icon" icon={faEnvelope} />
        <p className="contact-paragraph">hello@granddadsgroceries.com</p>
      </div>
      <div className="contact-flex">
        <FontAwesomeIcon className="contact-icon" icon={faPhone} />
        <p className="contact-paragraph">+1 (620) 878-0298</p>
      </div>
      <div>
        <a
          href="https://wa.me/message/R6SBLYFL7M3OA1"
          target="_blank"
          rel="noreferrer"
          className="contact-link"
        >
          <FontAwesomeIcon
            className="contact-icon contact-whatsapp"
            icon={faWhatsapp}
          />
          <p className="contact-paragraph">WhatsApp Business</p>
        </a>
      </div>
      <img src={groceryBag} alt="Grocery Bag" className="confirmation-image" />
    </div>
  );
}
