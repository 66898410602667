import "./Category.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons";

//hooks
import { useLocation } from "../../hooks/useLocation";

export default function Category({ homeCategories, handleSidebar }) {
  const { location } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="categories">
      <h1>Welcome to Granddad's Discount Groceries</h1>
      <h3>Browse Products by Category</h3>
      {location && location.ordersOpen && (
        <Link to={"products/?popular=TRUE"}>
          <div className="sold-items">
            <p> See what others are buying!</p>
            <FontAwesomeIcon icon={faTruckFast} className="faTruckFast" />
          </div>
        </Link>
      )}
      <div className="categories-grid">
        {homeCategories.map((category) => {
          return (
            <Link
              to={category.url}
              className="category-flex"
              key={category.url}
            >
              <div className="category-item">
                <img
                  className="category-image"
                  src={category.image}
                  alt={category.image}
                />
                <p className="category-title">{category.name}</p>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="category-paragraph" onClick={handleSidebar}>
        <h3 className="category-h3">See All Categories</h3>
      </div>
    </div>
  );
}
